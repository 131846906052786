import { render, staticRenderFns } from "./all.vue?vue&type=template&id=96b39bd8"
import script from "./all.vue?vue&type=script&lang=js"
export * from "./all.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TfImg: require('/usr/src/app/components/tf/img.vue').default,TfFooterInfo: require('/usr/src/app/components/tf/footer/info.vue').default})
