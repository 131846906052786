
export default {
	props: {
		promo: Object
	},
	data() {
		return {
			expDate: ``,
			exceptions: false
		}
	},
	methods: {
		formatDate() {
			if (this.promo.end_date) {
				const date = new Date(this.promo.end_date * 1000);
				const month = date.getMonth();
				const day = date.getDate();
				const year = date.getFullYear();
				this.expDate = `${month}/${day}/${year}`;
			}
		}
	},
	created() {
		this.formatDate();
	}
}
